import React from "react";
import { GitHub, Mail, Twitter, Linkedin } from "react-feather";
import styled, { useTheme } from "styled-components";

import { PageLink, PageContent, PageWrapper } from "constants/Mixins";
import Project from "components/Project";
import ThemeToggle from "components/ThemeToggle";

const HomePage = () => {
  const theme = useTheme();

  return (
    <PageWrapper>
      <PageContent>
        <Header>
          <Name>Edwin Zhang</Name>
          <Subtitle>Software &amp; Machine Learning Engineer</Subtitle>
          <Icons>
            <IconLink
              href={"https://github.com/edwinzhng"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHub />
            </IconLink>
            <IconLink
              href={"mailto:edwinzhang64@gmail.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mail />
            </IconLink>
            <IconLink
              href={"https://twitter.com/edwinzhng"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </IconLink>
            <IconLink
              href={"https://linkedin.com/in/edwin-zhang"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </IconLink>
          </Icons>
          <ThemeToggle />
        </Header>
        <Section>
          <SectionTitle>About</SectionTitle>
          <Description>
            Hi, I'm Edwin! I'm a product-focused software engineer that loves
            shipping things fast without compromising on craftsmanship,
            currently building software for private markets at{" "}
            <PageLink
              href={"https://www.angellist.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              AngelList
            </PageLink>
            . Previously, I worked on voice AI at{" "}
            <PageLink
              href={"https://vapi.ai"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Vapi
            </PageLink>
            , AI therapy at{" "}
            <PageLink
              href={"https://slingshot.xyz"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Slingshot
            </PageLink>
            , and recommender systems at{" "}
            <PageLink
              href={"https://quora.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Quora
            </PageLink>
            .
          </Description>
          <Description>
            I graduated with a degree in computer science from the University of
            Waterloo. During my studies, I interned at{" "}
            <PageLink
              href={"https://shopify.ca"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Shopify
            </PageLink>
            ,{" "}
            <PageLink
              href={"https://linkedin.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </PageLink>
            , and{" "}
            <PageLink
              href={"https://databricks.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Databricks
            </PageLink>
            , and was also involved in undergrad research, where I led NLP and
            information retrieval projects under the supervision of{" "}
            <PageLink
              href={"https://cs.uwaterloo.ca/~jimmylin/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Jimmy Lin
            </PageLink>
            .
          </Description>
          <Description>
            On the side, I built, and now maintain{" "}
            <PageLink
              href={"https://uwflow.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              UW Flow
            </PageLink>
            , a course and professor review platform for the University of
            Waterloo with 25,000+ monthly active users.
          </Description>
          <Description marginBottom={0}>
            I&apos;m inspired by well-designed products, machines that can
            learn, nature,{" "}
            <PageLink
              href={
                "https://vsco.co/1080pickles/galleries/662561543caf5cd13c4cd17c"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              dogs
            </PageLink>
            , music, and playing{" "}
            <PageLink
              href={"https://www.youtube.com/watch?v=SAukrpTEvZA"}
              target="_blank"
              rel="noopener noreferrer"
            >
              underwater hockey
            </PageLink>
            .
          </Description>
        </Section>
        <Section>
          <SectionTitle>Projects</SectionTitle>
          <Project
            title="UW Flow"
            description="A course and professor review platform used by 25,000+ students to plan courses every month"
            demoUrl="https://uwflow.com"
            tags={["Development", "Web"]}
            tagColors={[theme.red, theme.blue]}
            margin="0"
          />
          <Project
            title="Covidex"
            description="Neural search engine for COVID-19 research using state-of-the-art transfomer models"
            codeUrl="https://github.com/castorini/covidex"
            paperUrl="https://www.aclweb.org/anthology/2020.sdp-1.5/"
            talkUrl="https://slideslive.com/38940714"
            tags={["Research", "Development", "Machine Learning", "Web"]}
            tagColors={[theme.green, theme.red, theme.purple, theme.blue]}
          />
          <Project
            title="Howl"
            description="Python wake-word detection toolkit"
            codeUrl="https://github.com/castorini/howl"
            tags={["Research", "Development", "Machine Learning"]}
            tagColors={[theme.green, theme.red, theme.purple]}
          />
          <Project
            title="Chatty Goose"
            description="Python framework for conversational search"
            codeUrl="https://github.com/castorini/chatty-goose"
            paperUrl="https://cs.uwaterloo.ca/~jimmylin/publications/Zhang_etal_SIGIR2021_chatty-goose.pdf"
            tags={["Research", "Development", "Machine Learning"]}
            tagColors={[theme.green, theme.red, theme.purple]}
          />
        </Section>
      </PageContent>
    </PageWrapper>
  );
};

export default HomePage;

const Header = styled.div`
  margin: 120px auto 100px auto;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.primary};
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.subtitle};
`;

const IconLink = styled(PageLink)`
  &:hover {
    background-size: 0;
    svg {
      fill: ${({ theme }) => theme.accent};
      transition: 0.2s all ease-in-out;
    }
  }
`;

const Icons = styled.div`
  display: inline-block;
  margin-right: 24px;

  svg {
    width: 22px;
    margin-right: 16px;
    stroke: ${({ theme }) => theme.subtitle};
  }
`;

const Description = styled.div<{ marginBottom?: number }>`
  line-height: 1.5;
  margin-bottom: ${({ marginBottom = 24 }) => marginBottom}px;
`;

const Section = styled.div`
  margin-bottom: 100px;
`;

const SectionTitle = styled.div`
  display: inline-block;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 32px;

  &::after {
    content: "";
    background-color: ${({ theme }) => theme.blue};
    height: 8px;
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.5;
    transition: 0.2s all ease-in-out;
  }
`;
